<template>
	<el-card class="box-card" body-style="min-height: 300px">
		<div slot="header" class="clearfix">
			<span>{{ $t('sales.order.awb-status.card-title') }}</span>
		</div>

		<el-table :data="awbs" :empty-text="$t('sales.order.awb.no-awbs')" style="width: 100%;" size="small" border max-height="260px">
			<el-table-column prop="awb_number" :label="$t('sales.order.columns.awb_number')"></el-table-column>
			<el-table-column prop="added" :label="$t('sales.order.columns.generated')" width="120px"></el-table-column>

			<el-table-column :label="$t('sales.order.columns.status')">
				<template slot-scope="scope">
					<el-tag effect="dark" :class="'awb-status-' + scope.row.status_id">{{
						$t('sales.order.awb.status-' + scope.row.status_id)
					}}</el-tag>
				</template>
			</el-table-column>
		</el-table>
	</el-card>
</template>

<script>
import { sync, call, get } from 'vuex-pathify'
import createSalesStore from '@/store/modules/sales/index.js'

export default {
	computed: {
		awbs: get('sales/order@awb.list')
	},
	beforeCreate() {
		if (!this.$store.state.sales) {
			this.$store.registerModule('sales', createSalesStore())
		}
	},
	beforeDestroy() {
		if (this.$store.state.sales) {
			this.$store.unregisterModule('sales')
		}
	}
}
</script>
